export class VideoProviderService {
  static videoUrl(url: string): string {
    if (url.includes('youtube')) {
      const videoId = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&\?]*)/,)?.[1];
      return `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&mute=1&controls=0&showinfo=0&modestbranding=1&rel=0&playlist=${videoId}&wmode=transparent`;
    }

    if (url.includes('vimeo')) {
      const videoId = url.match(/\/video\/(\d+)/)?.[1];
      return `https://player.vimeo.com/video/${videoId}?autoplay=1&autopause=0&loop=1&muted=1&title=0&portrait=0&byline=0&controls=0&keyboard=0&speed=0`;
    }

    if (url.includes('cloudflare')) {
      const userIdMatch = url.match(/customer-([^\.]+)/)?.[1];
      const videoIdMatch = url.match(/cloudflarestream\.com\/([^\/]+)/)?.[1];
      const posterMatch = url.match(/poster=([^&]+)/)?.[1];
      let generatedUrl = `https://customer-${userIdMatch}.cloudflarestream.com/${videoIdMatch}/iframe?muted=true&preload=true&loop=true&autoplay=true`;
      if(posterMatch) {
        generatedUrl += `&poster=${posterMatch}`; 
      }
      return generatedUrl;
    }

    return url
  }
}
